import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LOADING_STATE } from "src/types/constants";
import ModelLifeCycleService, {
  AssignReviewJobsPayload,
} from "src/services/modelLifeCycleService";
import { createFileUrl, downloadFile } from "src/utils/fileUtil";
import CommonService, {
  ExtractTextHighlightPayload,
  TranslationPayload,
} from "src/services/commonService";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import { generateTimestamp } from "src/utils/dateUtil";

export enum ReviewStatus {
  REVIEWED = "Reviewed",
  UNREVIEWED = "Unreviewed",
}

export enum ReviewResult {
  LLM_CORRECT = "LLM is Correct",
  HUMAN_CORRECT = "Human is correct",
  INADEQUATE = "Inadequate Information",
  NEITHER = "Neither is correct",
  BOTH_CORRECT = "Both are correct",
  NOT_REVIEWED_YET = "Not reviewed yet",
}

export type ReviewFieldEntry = {
  fieldName: string;
  answer: string[];
};

export type ReviewEntry = {
  reviewer: string;
  reviewStartedAt: string;
  reviewSubmittedAt: string;
  fields: ReviewFieldEntry[];
};

export type GroundTruthField = {
  fieldName: string;
  userAnswer: string;
  llmClarification: string;
  llmAnswer: string;
  confirmedAnswer: string | string[];
  reviewResult: ReviewResult;
};

export type GroundTruthData = {
  modelId: string;
  sourceId: string;
  sourceText: string;
  fields: GroundTruthField[];
  timestamp: string;
  status: ReviewStatus;
  reviewer: string;
  reviewContributors?: string[];
  pendingReviewers?: string[];
  reviewHistory?: ReviewEntry[];
  metadata?: Record<string, any>;
  adminReviewers?: string[];
};

export type GroundTruthDataListView = {
  sourceId: string;
  status: ReviewStatus;
  reviewer: string;
  reviewContributors?: string[];
  pendingReviewers?: string[];
  adminReviewers?: string[];
};

const MULTI_OPTION_DELIMITER = "|";

export type GroundTruthState = {
  groundTruthListIndex: number;
  groundTruthDataListView: GroundTruthDataListView[];
  groundTruthDataTotalCount: number;
  groundTruthDataTotalReviewedCount: number;
  assignedReviewTasks: Record<string, number>;
  pendingReviewTasks: Record<string, number>;
  assignedValidationTasks: Record<string, number>;
  pendingValidationTasks: Record<string, number>;
  selectedGroundTruthData: GroundTruthData;
  itemsPerPage: number;
  currentPage: number;
  isBlind: boolean;
  showUserAnswerColumn: boolean;
  showLLMAnswerColumn: boolean;
  groundTruthDataFieldIndex: number;
  translation: string;
  showTranslation: boolean;
  showHighlight: boolean;
  highlightField: string;
  pageLoadTimestamp: string;
  assignmentCSV?: File;
  assignmentError: string;
  getListViewLoading: LOADING_STATE;
  getGroundTruthDataLoading: LOADING_STATE;
  getGroundTruthDataStatistics: LOADING_STATE;
  updateGroundTruthDataLoading: LOADING_STATE;
  assignReviewJobLoading: LOADING_STATE;
  translationLoading: LOADING_STATE;
  textHighlightLoading: LOADING_STATE;
};

export const initialGroundTruthData: GroundTruthData = {
  sourceId: "",
  modelId: "",
  sourceText: "",
  fields: [],
  timestamp: "",
  status: ReviewStatus.UNREVIEWED,
  reviewer: "",
  reviewContributors: [],
  reviewHistory: [],
};

const initialState: GroundTruthState = {
  groundTruthListIndex: -1,
  groundTruthDataListView: [],
  groundTruthDataTotalCount: 0,
  groundTruthDataTotalReviewedCount: 0,
  assignedReviewTasks: {},
  pendingReviewTasks: {},
  assignedValidationTasks: {},
  pendingValidationTasks: {},
  selectedGroundTruthData: initialGroundTruthData,
  itemsPerPage: 50,
  currentPage: 1,
  isBlind: true,
  showUserAnswerColumn: true,
  showLLMAnswerColumn: true,
  groundTruthDataFieldIndex: -1,
  translation: "",
  highlightField: "",
  showTranslation: false,
  showHighlight: true,
  assignmentError: "",
  getListViewLoading: "idle",
  getGroundTruthDataLoading: "idle",
  updateGroundTruthDataLoading: "idle",
  assignReviewJobLoading: "idle",
  getGroundTruthDataStatistics: "idle",
  translationLoading: "idle",
  textHighlightLoading: "idle",
  pageLoadTimestamp: "",
};

export const GROUND_TRUTH_UPLOAD_URL = "/model_life_cycle/upload";

export const getGroundTruthDataListView = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthDataListView",
  async (modelId: string) => {
    const { data } =
      await ModelLifeCycleService.getGroundTruthDataListView(modelId);
    return data;
  },
);

export const getGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/getGroundTruthData",
  async (payload: { modelId: string; sourceId: string }) => {
    const { data } = await ModelLifeCycleService.getGroundTruthData(
      payload.modelId,
      payload.sourceId,
    );
    return data;
  },
);

export const assignReviewJobs = createAsyncThunk(
  "modelLifeCycle/groundTruth/assignReviewJobs",
  async (payload: AssignReviewJobsPayload) => {
    const { data } = await ModelLifeCycleService.assignReviewJobs(payload);
    return data;
  },
);

export const downloadGroundTruthsInCSV = createAsyncThunk(
  "modelLifeCycle/getetGroundTruthsCSV",
  async (payload: { modelId: string }) => {
    const { data } =
      await ModelLifeCycleService.downloadGroundTruthsInCSV(payload);
    return data;
  },
);

const addReviewHistory = (payload: {
  groundTruthData: GroundTruthData;
  reviewer: string;
  pageLoadTimestamp: string;
}): GroundTruthData => {
  const newReviewEntry: ReviewEntry = {
    reviewer: payload.reviewer,
    reviewStartedAt: payload.pageLoadTimestamp,
    reviewSubmittedAt: generateTimestamp(),
    fields: payload.groundTruthData.fields.map(toReviewFieldEntry),
  };

  const currentReviewHistory = payload.groundTruthData.reviewHistory || [];
  const currentReviewContributors =
    payload.groundTruthData.reviewContributors || [];
  const currentPendingReviewers =
    payload.groundTruthData.pendingReviewers || [];

  return {
    ...payload.groundTruthData,
    reviewHistory: [...currentReviewHistory, newReviewEntry],
    reviewContributors: currentReviewContributors.includes(payload.reviewer)
      ? currentReviewContributors
      : [...currentReviewContributors, payload.reviewer],
    pendingReviewers: currentPendingReviewers.filter(
      (reviewer) => reviewer !== payload.reviewer,
    ),
  };
};

export const updateGroundTruthData = createAsyncThunk(
  "modelLifeCycle/groundTruth/updateGroundTruthData",
  async (payload: {
    groundTruthData: GroundTruthData;
    reviewer: string;
    pageLoadTimestamp: string;
  }) => {
    payload.groundTruthData = addReviewHistory(payload);
    const { data } = await ModelLifeCycleService.updateGroundTruthData({
      ...payload.groundTruthData,
      fields: payload.groundTruthData.fields.map(
        (groundTruthField: GroundTruthField) =>
          toGroundTruthFields(groundTruthField),
      ),
      reviewer: payload.reviewer,
    });
    return data;
  },
);

export const addReviewEntry = createAsyncThunk(
  "modelLifeCycle/groundTruth/addReviewEntry",
  async (payload: {
    groundTruthData: GroundTruthData;
    pageLoadTimestamp: string;
    reviewer: string;
  }) => {
    const { data } = await ModelLifeCycleService.addReviewEntry(
      payload.groundTruthData.modelId,
      payload.groundTruthData.sourceId,
      {
        fields: payload.groundTruthData.fields.map(
          (groundTruthField: GroundTruthField) =>
            toReviewFieldEntry(groundTruthField),
        ),
        reviewer: payload.reviewer, // this will be handel by backend
        reviewStartedAt: payload.pageLoadTimestamp,
        reviewSubmittedAt: generateTimestamp(),
      },
    );
    return data;
  },
);

export const translate = createAsyncThunk(
  "model/translation",
  async (payload: TranslationPayload) => {
    const { data } = await CommonService.translate(payload);
    return data;
  },
);

export const extractTextHighlightByFieldDefinition = createAsyncThunk(
  "model/textHighlight",
  async (payload: ExtractTextHighlightPayload) => {
    const { data } =
      await CommonService.extractTextHighlightByFieldDefinition(payload);
    return data;
  },
);

export const toGroundTruthFields = (item: GroundTruthField) => {
  return {
    ...item,
    confirmedAnswer:
      typeof item.confirmedAnswer === "string"
        ? item.confirmedAnswer
        : item.confirmedAnswer.join(MULTI_OPTION_DELIMITER),
  };
};

export const toReviewFieldEntry = (groundTruthField: GroundTruthField) => {
  return {
    fieldName: groundTruthField.fieldName,
    answer:
      typeof groundTruthField.confirmedAnswer === "string"
        ? [groundTruthField.confirmedAnswer]
        : groundTruthField.confirmedAnswer,
  };
};

export const setLLMAnswerForUnreviewedGroundTruthData = (
  groundTruthData: GroundTruthData,
) => {
  if (groundTruthData.status === ReviewStatus.UNREVIEWED) {
    groundTruthData.fields.forEach((groundTruthField) => {
      if (
        compareStringsIgnoreCase(
          groundTruthField.llmAnswer,
          groundTruthField.userAnswer,
        )
      ) {
        groundTruthField.confirmedAnswer = groundTruthField.llmAnswer;
        groundTruthField.reviewResult = ReviewResult.LLM_CORRECT;
      } else {
        groundTruthField.confirmedAnswer = "";
        groundTruthField.reviewResult = ReviewResult.NOT_REVIEWED_YET;
      }
    });
  }
  return groundTruthData;
};

/** Data Extraction Page Slice */
const { reducer, actions } = createSlice({
  name: "modelLifeCycle/groundTruthReviewPageSlice",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setIsBlind: (state, { payload }) => {
      state.isBlind = payload;
    },
    setItemPerPage: (state, { payload }) => {
      state.itemsPerPage = payload;
    },
    setFieldIndex: (state, { payload }) => {
      state.groundTruthListIndex = state.groundTruthDataListView.findIndex(
        (groundTruthDataView) => groundTruthDataView.sourceId === payload,
      );
    },
    setShowTranslation: (state, { payload }) => {
      state.showTranslation = payload;
    },
    setShowHighlight: (state, { payload }) => {
      state.showHighlight = payload;
    },
    setTranslation: (state, { payload }) => {
      state.translation = payload;
    },
    setHighlightField: (state, { payload }) => {
      state.highlightField = payload;
    },
    setAssignmentCSV: (state, { payload }) => {
      state.assignmentCSV = payload;
    },
    setFieldConfirmedAnswer: (state, { payload }) => {
      state.selectedGroundTruthData.fields[payload.fieldIndex].confirmedAnswer =
        payload.confirmedAnswer;
      if (
        payload.reviewResult === ReviewResult.BOTH_CORRECT ||
        payload.reviewResult === ReviewResult.INADEQUATE
      ) {
        state.selectedGroundTruthData.fields[payload.fieldIndex].reviewResult =
          payload.reviewResult;
      } else {
        if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].llmAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.LLM_CORRECT;
        } else if (
          payload.confirmedAnswer ===
          state.selectedGroundTruthData.fields[payload.fieldIndex].userAnswer
        ) {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.HUMAN_CORRECT;
        } else {
          state.selectedGroundTruthData.fields[
            payload.fieldIndex
          ].reviewResult = ReviewResult.NEITHER;
        }
      }
    },
    updateConfirmedAnswersFromHistory: (state, { payload }) => {
      const alias = payload;

      // Only process if status is UNREVIEWED or has reviewHistory
      if (
        state.selectedGroundTruthData.status === ReviewStatus.REVIEWED ||
        !state.selectedGroundTruthData.reviewHistory
      ) {
        return;
      }
      // Get review entries for the current reviewer
      const currentReviewerEntries =
        state.selectedGroundTruthData.reviewHistory?.filter(
          (entry) => entry.reviewer === alias,
        );

      // If no previous entries from this reviewer, leave confirmedAnswer as is
      if (!currentReviewerEntries || currentReviewerEntries.length === 0) {
        return;
      }

      // Find the latest review entry by timestamp
      const latestEntry = currentReviewerEntries.reduce((latest, current) => {
        return new Date(current.reviewSubmittedAt) >
          new Date(latest.reviewSubmittedAt)
          ? current
          : latest;
      }, currentReviewerEntries[0]);

      // Update the confirmed answers based on the latest entry
      state.selectedGroundTruthData.fields =
        state.selectedGroundTruthData.fields.map((field: GroundTruthField) => ({
          ...field,
          confirmedAnswer:
            latestEntry.fields.find(
              (fieldEntry: ReviewFieldEntry) =>
                fieldEntry.fieldName === field.fieldName,
            )?.answer || field.confirmedAnswer,
        }));
    },
    sortAndFilterGroundTruthDataListView: (state, { payload }) => {
      // in short term use requiredReviewerCount as a flag for assignment base GT review
      // since the multi anonymous person isn't required anymore
      const isAssignmentBasedReview = payload.model.requiredReviewerCount >= 1;
      if (!payload.isAdmin && isAssignmentBasedReview) {
        state.groundTruthDataListView = state.groundTruthDataListView.filter(
          (item) =>
            item.pendingReviewers?.includes(payload.alias) ||
            item.reviewContributors?.includes(payload.alias),
        );
      }

      // Sort the list
      state.groundTruthDataListView.sort((a, b) => {
        const getItemPriority = (item: GroundTruthDataListView): number => {
          if (isAssignmentBasedReview) {
            // Check if it's a validation task (admin review after initial review)
            if (
              item.adminReviewers?.includes(payload.alias) &&
              item.pendingReviewers?.includes(payload.alias) &&
              (item.reviewContributors?.length ?? 0) >= 1
            ) {
              return 1; // Highest priority - validation tasks
            }
            // Check if it's a pending review task
            if (
              item.pendingReviewers?.includes(payload.alias) &&
              !item.reviewContributors?.includes(payload.alias)
            ) {
              return 2; // Second priority - review tasks
            }
            // Already reviewed by this user
            if (item.reviewContributors?.includes(payload.alias)) {
              return 3; // Lowest priority - reviewed items
            }
            return 4; // Other cases
          } else {
            // Fall back to original logic for non-assignment based review
            if (
              item.status === ReviewStatus.REVIEWED ||
              item.reviewContributors?.includes(payload.alias)
            ) {
              return 3;
            }
            return 2;
          }
        };

        const priorityA = getItemPriority(a);
        const priorityB = getItemPriority(b);

        // First sort by priority
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        // If same priority, sort by sourceId
        return a.sourceId.localeCompare(b.sourceId);
      });
    },
    resetListViewPage: (state) => {
      state.getListViewLoading = "idle";
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
      state.translationLoading = "idle";
      state.textHighlightLoading = "idle";
      state.highlightField = "";
      state.translation = "";
      state.showTranslation = false;
    },
    resetGroundTruthReviewPage: (state) => {
      state.getGroundTruthDataLoading = "idle";
      state.getGroundTruthDataStatistics = "idle";
      state.updateGroundTruthDataLoading = "idle";
      state.translationLoading = "idle";
      state.textHighlightLoading = "idle";
      state.highlightField = "";
      state.translation = "";
      state.showTranslation = false;
      state.pageLoadTimestamp = generateTimestamp();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroundTruthDataListView.pending, (state) => {
      state.getListViewLoading = "pending";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataTotalReviewedCount = 0;
    });
    builder.addCase(
      getGroundTruthDataListView.fulfilled,
      (state, { payload }) => {
        state.getListViewLoading = "fulfilled";
        state.groundTruthDataListView = payload;
        state.groundTruthDataTotalCount = payload.length;

        // Initialize counters
        state.assignedReviewTasks = {};
        state.pendingReviewTasks = {};
        state.assignedValidationTasks = {};
        state.pendingValidationTasks = {};
        state.groundTruthDataTotalReviewedCount = 0;

        // Helper function to filter out "llm" and get unique aliases
        const getNonLLMAliases = (aliases?: string[]) =>
          aliases?.filter((alias) => alias !== "llm") || [];

        // Collect all unique non-LLM aliases
        const allUniqueAliases = new Set<string>(
          payload.flatMap((item: GroundTruthDataListView) => [
            ...getNonLLMAliases(item.reviewContributors),
            ...getNonLLMAliases(item.pendingReviewers),
            ...getNonLLMAliases(item.adminReviewers),
          ]),
        );

        // Initialize counters for all aliases
        allUniqueAliases.forEach((alias) => {
          state.assignedReviewTasks[alias] = 0;
          state.pendingReviewTasks[alias] = 0;
          state.assignedValidationTasks[alias] = 0;
          state.pendingValidationTasks[alias] = 0;
        });

        // Count tasks for each item
        payload.forEach((item: GroundTruthDataListView) => {
          // Count reviewed items
          const nonLLMContributors = getNonLLMAliases(item.reviewContributors);
          if (
            nonLLMContributors.length > 0 ||
            item.status === ReviewStatus.REVIEWED
          ) {
            state.groundTruthDataTotalReviewedCount++;
          }

          const reviewers = getNonLLMAliases(item.reviewContributors);
          const pendingReviewers = getNonLLMAliases(item.pendingReviewers);
          const adminReviewers = getNonLLMAliases(item.adminReviewers);

          // Process each non-LLM alias in the item
          new Set([
            ...reviewers,
            ...pendingReviewers,
            ...adminReviewers,
          ]).forEach((alias) => {
            // Rule 1: Assigned review tasks (if reviewer or pending, but not admin)
            if (
              (reviewers.includes(alias) || pendingReviewers.includes(alias)) &&
              !adminReviewers.includes(alias)
            ) {
              state.assignedReviewTasks[alias]++;
            }

            // Rule 2: Pending review tasks (if pending but not admin)
            if (
              pendingReviewers.includes(alias) &&
              !adminReviewers.includes(alias)
            ) {
              state.pendingReviewTasks[alias]++;
            }

            // Rule 3: Pending validation tasks (if pending and admin)
            if (
              pendingReviewers.includes(alias) &&
              adminReviewers.includes(alias)
            ) {
              state.pendingValidationTasks[alias]++;
            }

            // Rule 4: Assigned validation tasks (if admin)
            if (adminReviewers.includes(alias)) {
              state.assignedValidationTasks[alias]++;
            }
          });
        });
      },
    );

    builder.addCase(getGroundTruthDataListView.rejected, (state) => {
      state.getListViewLoading = "rejected";
      state.groundTruthDataTotalCount = 0;
      state.groundTruthDataTotalReviewedCount = 0;
    });
    builder.addCase(assignReviewJobs.pending, (state) => {
      state.assignReviewJobLoading = "pending";
    });
    builder.addCase(assignReviewJobs.fulfilled, (state, { payload }) => {
      state.assignReviewJobLoading = "fulfilled";
    });
    builder.addCase(assignReviewJobs.rejected, (state) => {
      state.assignReviewJobLoading = "rejected";
    });
    builder.addCase(updateGroundTruthData.pending, (state) => {
      state.updateGroundTruthDataLoading = "pending";
    });
    builder.addCase(updateGroundTruthData.fulfilled, (state, { payload }) => {
      state.updateGroundTruthDataLoading = "fulfilled";
      const index = state.groundTruthDataListView.findIndex(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId === payload.sourceId,
      );
      if (index !== -1) {
        state.groundTruthDataListView[index].status = payload.status;
      }
    });
    builder.addCase(updateGroundTruthData.rejected, (state) => {
      state.updateGroundTruthDataLoading = "rejected";
    });
    builder.addCase(addReviewEntry.fulfilled, (state, { payload }) => {
      state.updateGroundTruthDataLoading = "fulfilled";
      const index = state.groundTruthDataListView.findIndex(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId === payload.sourceId,
      );
      if (index !== -1) {
        state.groundTruthDataListView[index].status = payload.status;
      }
    });
    builder.addCase(addReviewEntry.rejected, (state) => {
      state.updateGroundTruthDataLoading = "rejected";
    });
    builder.addCase(getGroundTruthData.pending, (state) => {
      state.getGroundTruthDataLoading = "pending";
    });
    builder.addCase(getGroundTruthData.fulfilled, (state, { payload }) => {
      state.getGroundTruthDataLoading = "fulfilled";
      state.selectedGroundTruthData =
        setLLMAnswerForUnreviewedGroundTruthData(payload);
      state.selectedGroundTruthData.fields =
        state.selectedGroundTruthData.fields.map((field) => {
          if (
            typeof field.confirmedAnswer === "string" &&
            field.confirmedAnswer.includes("|")
          ) {
            return {
              ...field,
              confirmedAnswer: field.confirmedAnswer
                .split("|")
                .map((answer) => answer.trim())
                .filter((answer) => answer !== ""), // Remove empty strings
            };
          }
          return field;
        });
      state.showUserAnswerColumn = state.selectedGroundTruthData.fields.some(
        (field) => field.userAnswer.trim() !== "",
      );
      state.showLLMAnswerColumn = state.selectedGroundTruthData.fields.some(
        (field) => field.llmAnswer.trim() !== "",
      );
      state.pageLoadTimestamp = generateTimestamp();
    });
    builder.addCase(getGroundTruthData.rejected, (state) => {
      state.getGroundTruthDataLoading = "rejected";
    });
    builder.addCase(
      downloadGroundTruthsInCSV.fulfilled,
      (state, { payload }) => {
        if (payload) {
          // download file
          downloadFile(createFileUrl(payload, "text/csv"), "ground_truths.csv");
        }
      },
    );
    builder.addCase(translate.pending, (state) => {
      state.translationLoading = "pending";
      state.translation = "";
      state.showTranslation = false;
    });
    builder.addCase(translate.fulfilled, (state, { payload }) => {
      state.translationLoading = "fulfilled";
      state.translation = payload;
      state.showTranslation = true;
    });
    builder.addCase(translate.rejected, (state) => {
      state.translationLoading = "rejected";
      state.showTranslation = false;
    });
    builder.addCase(extractTextHighlightByFieldDefinition.pending, (state) => {
      state.textHighlightLoading = "pending";
    });
    builder.addCase(
      extractTextHighlightByFieldDefinition.fulfilled,
      (state, { payload }) => {
        state.textHighlightLoading = "fulfilled";
        state.showHighlight = true;

        // Update llmClarification only for fields that don't have it
        state.selectedGroundTruthData.fields =
          state.selectedGroundTruthData.fields.map((field) => {
            // Only update if current llmClarification is empty or undefined
            if (
              !field.llmClarification ||
              field.llmClarification.trim() === ""
            ) {
              return {
                ...field,
                llmClarification: payload[field.fieldName]?.join("|") || "",
              };
            }
            // Keep existing field unchanged if it already has llmClarification
            return field;
          });
      },
    );
    builder.addCase(extractTextHighlightByFieldDefinition.rejected, (state) => {
      state.textHighlightLoading = "rejected";
      state.showHighlight = false;
    });
  },
});

export const {
  setFieldConfirmedAnswer,
  setFieldIndex,
  setCurrentPage,
  setIsBlind,
  setItemPerPage,
  resetGroundTruthReviewPage,
  resetListViewPage,
  setShowTranslation,
  setShowHighlight,
  setHighlightField,
  updateConfirmedAnswersFromHistory,
  sortAndFilterGroundTruthDataListView,
  setAssignmentCSV,
} = actions;

export default reducer;
