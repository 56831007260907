import { DonutChartProps } from "src/components/audit/donutChart";
import { AuditDataView, AuditStatus } from "src/store/auditSlice";

export const highlightText = (clarifications: string[]): void => {
  const paragraph = document.getElementById("incident-description");
  if (paragraph) {
    let innerHTML = paragraph.innerHTML;

    clarifications.forEach((clarification) => {
      // Remove leading and trailing non-alphanumeric characters
      clarification = clarification
        .trim()
        .replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, "");
      const regex = new RegExp(
        `(\\([^)]*?${clarification.replace(/([()\\])/g, "\\$1")}[^)]*?\\))|(${clarification.replace(/([()\\])/g, "\\$1")})`,
        "gi",
      );

      innerHTML = innerHTML.replace(regex, (match, p1, p2) => {
        if (p1) {
          return `<span class="highlight">${p1}</span>`;
        } else if (p2) {
          return `<span class="highlight">${p2}</span>`;
        }
        return match;
      });
    });

    paragraph.innerHTML = innerHTML;

    const firstHighlight = paragraph.querySelector(".highlight");
    if (firstHighlight) {
      firstHighlight.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
};

export const moveToTextTop = (): void => {
  const paragraph = document.getElementById("incident-description-container");
  if (paragraph) {
    paragraph.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};

export const removeHighlightedText = (): void => {
  const paragraph = document.getElementById("incident-description");
  if (paragraph) {
    const innerHTML = paragraph.innerHTML;

    paragraph.innerHTML = innerHTML.replace(
      /<span class="highlight">|<\/span>/g,
      "",
    );
  }
};

export const getRatio = (data: DonutChartProps[] | undefined): string => {
  if (!data || !Array.isArray(data)) {
    return "0%";
  }

  const unreviewedData =
    data.find((obj) => obj.label === "Unreviewed")?.value ?? 0;
  const reviewedData = data.find((obj) => obj.label === "Reviewed")?.value ?? 0;

  if (unreviewedData + reviewedData === 0) {
    return "0%";
  }

  let percentage = (unreviewedData / (reviewedData + unreviewedData)) * 100;
  return percentage.toFixed(0) + "%";
};

export const findNextUnreviewedCase = (
  list: AuditDataView[],
  incidentId?: string,
) => {
  const currentIndex = incidentId
    ? list.findIndex((item) => item.incidentId === incidentId)
    : -1;

  return (
    // Try to find unreviewed case after current index
    (currentIndex !== -1 &&
      list
        .slice(currentIndex + 1)
        .find((item) => item.status === AuditStatus.UNREVIEWED)) ||
    // If not found, find any unreviewed case excluding current incidentId
    list
      .filter((item) => item.incidentId !== incidentId)
      .find((item) => item.status === AuditStatus.UNREVIEWED)
  );
};

export const isMultipleAnswerEqual = (
  answer: string,
  llmAnswer: string,
): boolean => {
  const austinAnswerSet = answer.toLowerCase().split("|");
  const llmAnswerSet = llmAnswer.toLowerCase().split("|");

  return (
    austinAnswerSet.length === llmAnswerSet.length &&
    [...austinAnswerSet].every((item) => llmAnswerSet.includes(item))
  );
};
