import React from "react";
import Link from "@amzn/meridian/link";
import Icon from "@amzn/meridian/icon";
import downloadTokens from "@amzn/meridian-tokens/base/icon/download-small";
import { useAppSelector } from "src/store/store";
import { downloadCSV } from "src/utils/csvUtil";

const REVIEWER_SEPARATOR = ";";

const GroundTruthReviewAllocationCSVDownloadLink: React.FC = () => {
  const { groundTruthDataListView } = useAppSelector(
    (state) => state.modelLifeCycleGroundTruthSlice,
  );

  const handleOnClick = () => {
    const headers = [
      "Source ID",
      "Pending Review",
      "Pending Validation",
      "Reviewed By",
    ];

    const rows = groundTruthDataListView.map((item) => {
      const { sourceId, pendingReviewers, adminReviewers, reviewContributors } =
        item;

      const pendingReview = (pendingReviewers || [])
        .filter((reviewer) => !(adminReviewers || []).includes(reviewer))
        .join(REVIEWER_SEPARATOR);

      const pendingValidation = (pendingReviewers || [])
        .filter((reviewer) => (adminReviewers || []).includes(reviewer))
        .join(REVIEWER_SEPARATOR);

      const reviewedBy = (reviewContributors || []).join(REVIEWER_SEPARATOR);

      return [sourceId || "", pendingReview, pendingValidation, reviewedBy];
    });

    const csvContent = [
      headers.join(","),
      ...rows.map((row) =>
        row
          .map((field) => {
            let processedField = field || "";
            return processedField.includes(",")
              ? `"${processedField}"`
              : processedField;
          })
          .join(","),
      ),
    ].join("\n");

    const date = new Date().toISOString().split("T")[0];
    const filename = `ground_truth_review_allocation_${date}.csv`;

    downloadCSV(csvContent, filename);
  };

  return (
    <Link onClick={handleOnClick} disabled={!groundTruthDataListView?.length}>
      <Icon tokens={downloadTokens} />
      Download review allocation
    </Link>
  );
};

export default GroundTruthReviewAllocationCSVDownloadLink;
