import React from "react";
import "./ReviewHistoryStyles.scss";
import { ReviewEntry } from "src/store/modelLifeCycleGroundTruthSlice";
import { HIERARCHY_SEPARATOR } from "src/constants";

interface ReviewHistoryWithColorCodingProps {
  reviewHistory: ReviewEntry[];
  fieldName: string;
}

interface ColorInfo {
  bg: string;
  text: string;
}

// Create color mapping
const colors: ColorInfo[] = [
  { bg: "#E3F2FD", text: "#0D47A1" }, // Light blue
  { bg: "#E8F5E9", text: "#1B5E20" }, // Light green
  { bg: "#FFF3E0", text: "#E65100" }, // Light orange
  { bg: "#F3E5F5", text: "#4A148C" }, // Light purple
  { bg: "#FFEBEE", text: "#B71C1C" }, // Light red
  { bg: "#E0F7FA", text: "#006064" }, // Light cyan
  { bg: "#FFFDE7", text: "#F57F17" }, // Light yellow
  { bg: "#EFEBE9", text: "#3E2723" }, // Light brown
];

function createAnswerColorMap(
  reviewHistory: ReviewEntry[],
  fieldName: string,
): Record<string, ColorInfo> {
  const uniqueAnswers = new Set<string>();

  // Collect all answers from the review history
  reviewHistory.forEach((entry) => {
    entry.fields
      .filter((field) => field.fieldName === fieldName)[0]
      .answer.forEach((answerItem: string) => {
        uniqueAnswers.add(answerItem);
      });
  });

  const answerColorMap: Record<string, ColorInfo> = {};

  Array.from(uniqueAnswers).forEach((answer, index) => {
    // Use modulo to cycle through colors if there are more answers than colors
    const colorIndex = index % colors.length;
    answerColorMap[answer] = colors[colorIndex];
  });

  return answerColorMap;
}

function deduplicateReviewsByReviewer(
  reviewHistory: ReviewEntry[],
): ReviewEntry[] {
  return Object.values(
    Array.from(reviewHistory).reduce<Record<string, ReviewEntry>>(
      (acc, review) => {
        const reviewer = review.reviewer;
        if (
          !acc[reviewer] ||
          new Date(review.reviewSubmittedAt) >
            new Date(acc[reviewer].reviewSubmittedAt)
        ) {
          acc[reviewer] = review;
        }

        return acc;
      },
      {},
    ),
  );
}

const ReviewHistoryWithColorCoding: React.FC<
  ReviewHistoryWithColorCodingProps
> = ({ reviewHistory, fieldName }) => {
  reviewHistory = deduplicateReviewsByReviewer(reviewHistory);
  // Create the color map using the passed index
  const answerColorMap = createAnswerColorMap(reviewHistory, fieldName);

  return (
    <div className="review-history-container">
      {reviewHistory.map((entry, i) => {
        const answerArray = Array.from(
          entry.fields.filter((field) => field.fieldName === fieldName)[0]
            .answer,
        ).sort();

        return (
          <div key={i} className="review-row">
            <div className="reviewer-name">{`Source ${i + 1}:`}</div>
            <div className="answers-container">
              {answerArray.map((answerItem, answerIndex) => {
                const colorInfo = answerColorMap[answerItem];
                return (
                  <div
                    key={answerIndex}
                    className="answer-box"
                    style={{
                      backgroundColor: colorInfo.bg,
                      color: colorInfo.text,
                    }}
                  >
                    {answerItem.split(HIERARCHY_SEPARATOR).slice(-1)[0]}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReviewHistoryWithColorCoding;
