import React, { useEffect } from "react";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import {
  setFile,
  uploadNewRevision,
  resetUploadNewRevisionError,
  getCurrentGroundTruth,
} from "src/store/modelLifeCycleSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";
import { GROUND_TRUTH_UPLOAD_URL } from "src/store/modelLifeCycleGroundTruthSlice";
import { getFileInfoWithProperLength } from "src/utils/fileUtil";

const UploadNewRevision = () => {
  const { file, selectedFileTemplate } = useAppSelector(
    (state) => state.modelLifeCycleSlice,
  );
  const { selectedModelId } = useAppSelector((state) => state.modelSlice);
  const { uploadNewRevisionError, uploadNewRevisionOutput } = useAppSelector(
    (state) => state.modelLifeCycleSlice,
  );
  const { apiErrors } = useAppSelector((state) => state.errorBoundary);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (file && selectedModelId) {
      const payload = {
        modelId: selectedModelId,
        file: file,
        generateLLMClarification: true,
        fileTemplate: selectedFileTemplate,
      };
      dispatch(uploadNewRevision(payload));
    }
  }, [file]);

  useEffect(() => {
    dispatch(resetUploadNewRevisionError());
    dispatch(setFile(null));
    dispatch(getCurrentGroundTruth({ modelId: selectedModelId }));
  }, [selectedModelId, uploadNewRevisionOutput]);

  return (
    <FileInput
      type="single"
      accept=".csv"
      uploadButtonType="secondary"
      uploadButtonLabel="Upload ground truth to improve accuracy"
      onFileAttached={(acceptedFiles) => dispatch(setFile(acceptedFiles[0]))}
    >
      {file && (
        <FileDetails
          file={getFileInfoWithProperLength(file)}
          cancelUploadButtonLabel={`Cancel, ${file.name}`}
          removeFileButtonLabel={`Delete, ${file.name}`}
          error={!!uploadNewRevisionError}
          errorMessage={apiErrors[GROUND_TRUTH_UPLOAD_URL]}
          uploadComplete={!!uploadNewRevisionError}
        />
      )}
    </FileInput>
  );
};
export default UploadNewRevision;
