export const DEFAULT_LOCALE = "en-US";

export const DUMMY_MODEL_ID = "NEW_MODEL";

export const PAGINATION_OPTIONS = [10, 50, 100, 200];

export const INCIDENT_DESCRIPTION_COLUMN_NAME = "incident_description";

export const INCIDENT_ID_COLUMN_NAME = "incident_id";

export const CPT_AUDITOR_LDAP_GROUP = "whs-data-excavator-auditor-cpt";

export const GENERAL_USER_LDAP_GROUP = "whs-data-excavator-user";

export const ADMIN_LDAP_GROUP = "whs-data-excavator-admin";

export const ENGINEER_LDAP_GROUP = "workingwell-engineer";

export const SOURCE_ID = "source_id";

export const SOURCE_TEXT = "source_text";

export const INADEQUATE_INFORMATION = "Inadequate Information";

export const NO_OBJECT_INVOLVED = "No object involved";

export const OTHER = "Other";

export const HIERARCHY_SEPARATOR = "---";
