import React, { useEffect } from "react";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  assignReviewJobs,
  GROUND_TRUTH_UPLOAD_URL,
  setAssignmentCSV,
} from "src/store/modelLifeCycleGroundTruthSlice";
import { getFileInfoWithProperLength } from "src/utils/fileUtil";
import Button from "@amzn/meridian/button";

const ReviewAssignment = () => {
  const { assignmentCSV, assignReviewJobLoading, assignmentError } =
    useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);
  const { selectedModelId } = useAppSelector((state) => state.modelSlice);
  const { apiErrors } = useAppSelector((state) => state.errorBoundary);
  const dispatch = useAppDispatch();

  const handleAssignButtionClick = () => {
    if (assignmentCSV && selectedModelId) {
      const payload = {
        modelId: selectedModelId,
        file: assignmentCSV,
      };
      dispatch(assignReviewJobs(payload));
    }
  };

  return assignmentCSV && assignReviewJobLoading !== "fulfilled" ? (
    <>
      <Button onClick={() => handleAssignButtionClick()}>
        assign review jobs
      </Button>
      <FileDetails
        file={getFileInfoWithProperLength(assignmentCSV)}
        cancelUploadButtonLabel={`Cancel, ${assignmentCSV?.name}`}
        removeFileButtonLabel={`Delete, ${assignmentCSV?.name}`}
        error={!!assignmentError}
        // errorMessage={apiErrors[GROUND_TRUTH_UPLOAD_URL]}
        uploadComplete={assignmentCSV !== undefined}
      />
    </>
  ) : (
    <FileInput
      type="single"
      accept=".csv"
      uploadButtonType="tertiary"
      uploadButtonLabel="Upload csv to assign review jobs"
      onFileAttached={(acceptedFiles) =>
        dispatch(setAssignmentCSV(acceptedFiles[0]))
      }
    />
  );
};
export default ReviewAssignment;
