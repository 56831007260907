import React, { useState } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import { TemplateFormat } from "src/store/modelLifeCycleSlice";
import { setSelectedFileTemplate } from "src/store/modelLifeCycleSlice";
import { useAppDispatch, useAppSelector } from "src/store/store";

const fileTemplateFormats: TemplateFormat[] = [
  {
    id: "USER_ANSWER",
    value: "user answer",
    label: "With field inputs",
  },
  {
    id: "SOURCE_ID_AND_TEXT",
    value: "source id and text only",
    label: "Source id and source text only",
  },
  {
    id: "CONFIRMED_ANSWER",
    value: "confirmed answer",
    label: "With reviewed field inputs",
  },
];

const FileTempletaSelection = () => {
  const dispatch = useAppDispatch();
  const { selectedFileTemplate } = useAppSelector(
    (state) => state.modelLifeCycleSlice,
  );

  return (
    <Select
      label="Select a file template format to upload"
      value={selectedFileTemplate}
      onChange={(e) => dispatch(setSelectedFileTemplate(e))}
      width={400}
    >
      {fileTemplateFormats.map((template) => {
        return (
          <SelectOption
            key={template.id}
            value={template.value}
            label={template.label}
          ></SelectOption>
        );
      })}
    </Select>
  );
};

export default FileTempletaSelection;
