import React, { useEffect, useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Button from "@amzn/meridian/button";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  GroundTruthDataListView,
  ReviewStatus,
  resetListViewPage,
  setCurrentPage,
  setIsBlind,
  setItemPerPage,
  getGroundTruthDataListView,
  sortAndFilterGroundTruthDataListView,
} from "src/store/modelLifeCycleGroundTruthSlice";
import meridianColors from "@amzn/meridian-tokens/base/color";
import DonutChart from "../donutChart";
import PaginationWithDropdown from "src/components/audit/paginationWithDropdown";
import { Model, setSelectedModelId } from "src/store/modelManagementSlice";
import Coachmark from "@amzn/meridian/coachmark";
import _ from "lodash";
import "./modelGroundTruthListView.scss";
import { isValidRange } from "src/utils/numberUtil";
import Link from "@amzn/meridian/link";
import Toggle from "@amzn/meridian/toggle";
import ReviewAssignment from "src/components/validationWorkflow/reviewAssignment";
import { isAdminUser } from "src/utils/permissionUtil";
import Expander from "@amzn/meridian/expander";
import { isAssignedBasedReview } from "src/components/validationWorkflow/groundTruthReview/modelGroundTruthReviewUtil";
import GroundTruthReviewAllocationCSVDownloadLink from "src/components/validationWorkflow/groundTruthListView/reviewAllocationCSVDownloadLink";

const ModelGroundTruthListView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { modelId } = useParams();

  const {
    groundTruthDataListView,
    currentPage,
    isBlind,
    itemsPerPage,
    getListViewLoading,
    groundTruthDataTotalCount,
    groundTruthDataTotalReviewedCount,
    assignedReviewTasks,
    pendingReviewTasks,
    assignedValidationTasks,
    pendingValidationTasks,
  } = useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);

  const { token, alias, ldap } = useAppSelector((state) => state.user);
  const { selectedModel } = useAppSelector((state) => state.modelSlice);

  const getGroundTruthDataListViewStatus = (
    groundTruthDataListView: GroundTruthDataListView,
    selectedModel: Model,
    alias: string,
  ) => {
    // check if the alias been assigned review
    if (groundTruthDataListView.pendingReviewers?.includes(alias)) {
      if (groundTruthDataListView.reviewContributors?.includes(alias)) {
        return "Pending re-review";
      } else if (
        groundTruthDataListView.adminReviewers?.includes(alias) &&
        groundTruthDataListView.pendingReviewers?.includes(alias) &&
        groundTruthDataListView.reviewContributors &&
        groundTruthDataListView.reviewContributors?.length >= 1
      ) {
        return "Pending validation";
      } else {
        return "Pending review";
      }
      // if reviewer not being assigned, fall into multi-person review logic
    } else if (selectedModel.requiredReviewerCount) {
      if (groundTruthDataListView.reviewContributors?.includes(alias)) {
        return "Reviewed";
      } else {
        return "Pending review";
      }
      // default share review logic
    } else {
      return groundTruthDataListView.status === ReviewStatus.REVIEWED
        ? "Reviewed"
        : "Pending review";
    }
  };

  const getGroundTruthDataListViewReviewer = (
    groundTruthDataListView: GroundTruthDataListView,
    alias: string,
  ) => {
    return groundTruthDataListView.reviewContributors?.includes(alias)
      ? alias
      : groundTruthDataListView.reviewer;
  };

  // triggerred when review page redirect to list view
  useEffect(() => {
    dispatch(resetListViewPage());
  }, []);

  useEffect(() => {
    if (modelId && token && getListViewLoading !== "fulfilled") {
      dispatch(getGroundTruthDataListView(modelId));
    }
  }, [modelId, token]);

  useEffect(() => {
    if (modelId) {
      dispatch(setSelectedModelId(modelId));
    }
  }, [modelId]);

  useEffect(() => {
    if (getListViewLoading === "rejected") {
      navigate(`/validationWorkflow`);
    } else if (getListViewLoading === "fulfilled" && selectedModel.id) {
      dispatch(
        sortAndFilterGroundTruthDataListView({
          model: selectedModel,
          alias: alias,
          isAdmin: isAdminUser(ldap),
        }),
      );
    }
  }, [getListViewLoading, selectedModel]);

  const findNextUnreviewedCase = (
    list: GroundTruthDataListView[],
    sourceId?: string,
  ) => {
    return list
      .filter(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.sourceId !== sourceId,
      )
      .find(
        (groundTruthDataListView: GroundTruthDataListView) =>
          groundTruthDataListView.status === ReviewStatus.UNREVIEWED,
      );
  };

  const numberOfPages = Math.ceil(
    groundTruthDataListView.length / itemsPerPage,
  );
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = Math.min(
    groundTruthDataListView.length,
    firstVisibleIndex + itemsPerPage - 1,
  );

  const tableHeaderRowNames = ["Source ID", "Status", "Reviewer"];
  const ControlledExpander = (props: any) => {
    const [open, setOpen] = useState();
    return <Expander open={open} onChange={setOpen} {...props} />;
  };
  return (
    <Row widths={["75%", "25%"]}>
      <Column spacingInset={"0 500 500 500"}>
        <BreadcrumbGroup>
          <Breadcrumb onClick={() => navigate(`/validationWorkflow`)}>
            Validation workflow
          </Breadcrumb>
          <Breadcrumb
            onClick={() => navigate(`/validationWorkflow/${modelId}`)}
          >
            {selectedModel.name} - Processed
          </Breadcrumb>
        </BreadcrumbGroup>
        <Row widths={["65%", "35%"]}>
          <Row spacing={"400"}>
            <Column alignmentHorizontal="start" spacing={"300"}>
              <Text type={"d50"}>Ground Truth - Processed</Text>
              <Row spacing={"600"}>
                <Text type="b100">Model Id: {modelId}</Text>
                <Text type="b100">
                  Total Fields: {Object.keys(selectedModel.fields).length}
                </Text>
              </Row>
            </Column>
          </Row>
          <Row alignmentHorizontal="end" widths={["50%"]}>
            <Button
              onClick={() => {
                const next = findNextUnreviewedCase(groundTruthDataListView);
                if (next) {
                  navigate(`/validationWorkflow/${modelId}/${next.sourceId}`);
                } else {
                  navigate(
                    `/validationWorkflow/${modelId}/${groundTruthDataListView[0].sourceId}`,
                  );
                }
              }}
              disabled={groundTruthDataListView.length === 0}
            >
              Review
            </Button>
            <Column spacing={"none"}>
              <Toggle
                checked={isBlind}
                onChange={() => {
                  dispatch(setIsBlind(!isBlind));
                }}
              />
              <Text>Blinding</Text>
            </Column>
          </Row>
        </Row>
        <Column height="65vh" overflowY="auto">
          <Table
            showDividers={true}
            fixHeaderRows={true}
            stickyHeaderColumn={true}
            headerRows={1}
          >
            <TableRow backgroundColor={meridianColors.colorGray50}>
              {tableHeaderRowNames.map((name) => (
                <TableCell>
                  {name === "Insights Generated" ? (
                    <Row spacing={"none"}>
                      <Coachmark popoverPosition="top" animating={false}>
                        <Text>
                          The number represents: number of field extracted by
                          LLM excluding 'inadequate' result / number of total
                          field part of this model
                        </Text>
                      </Coachmark>
                      <Text type="h200">{name}</Text>
                    </Row>
                  ) : (
                    <Text type="h200">{name}</Text>
                  )}
                </TableCell>
              ))}
            </TableRow>
            {isValidRange(
              firstVisibleIndex,
              lastVisibleIndex,
              0,
              groundTruthDataListView.length,
            ) &&
              _.range(firstVisibleIndex, lastVisibleIndex).map((index) => {
                const totalField = Object.keys(
                  selectedModel.fields,
                ).length.toString();
                return (
                  groundTruthDataListView[index] && (
                    <TableRow
                      highlightOnHover
                      key={groundTruthDataListView[index].sourceId}
                    >
                      <TableCell>
                        <Link
                          type="secondary"
                          onClick={() => {
                            navigate(
                              `/validationWorkflow/${modelId}/${groundTruthDataListView[index].sourceId}`,
                            );
                          }}
                        >
                          {groundTruthDataListView[index].sourceId}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {getGroundTruthDataListViewStatus(
                          groundTruthDataListView[index],
                          selectedModel,
                          alias,
                        )}
                      </TableCell>
                      <TableCell>
                        {getGroundTruthDataListViewReviewer(
                          groundTruthDataListView[index],
                          alias,
                        )}
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </Table>
        </Column>
        <PaginationWithDropdown
          currentPage={currentPage}
          totalPages={numberOfPages}
          perPage={itemsPerPage}
          setPerPage={(pageNum) => {
            dispatch(setItemPerPage(pageNum));
            dispatch(setCurrentPage(1));
          }}
          setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
        />
      </Column>
      <Column
        height={"92vh"}
        className="incident-summary"
        alignmentHorizontal="start"
        spacingInset={"500"}
      >
        {isAdminUser(ldap) && (
          <>
            <Text type="h300">Overall Ground Truth Summary</Text>
            <DonutChart
              totalCount={groundTruthDataTotalCount}
              reviewedCount={groundTruthDataTotalReviewedCount}
              isLoading={getListViewLoading === "pending"}
              title="Total Ground Truth"
              subtitle="Remaining cases for review"
            />
            <ControlledExpander title="Assigned Review Summary" type="box">
              {Object.keys(assignedReviewTasks).map((alias) => {
                const completed =
                  assignedReviewTasks[alias] - pendingReviewTasks[alias];
                const total = assignedReviewTasks[alias];
                return (
                  <Column key={`review-${alias}`} spacing="300">
                    <Text>
                      {alias}: {completed}/{total} reviews completed
                    </Text>
                  </Column>
                );
              })}
            </ControlledExpander>
            <ControlledExpander title="Assigned Validation Summary" type="box">
              {Object.keys(assignedValidationTasks).map((alias) => {
                const completed =
                  assignedValidationTasks[alias] -
                  pendingValidationTasks[alias];
                const total = assignedValidationTasks[alias];
                return (
                  <Column key={`review-${alias}`} spacing="300">
                    <Text>
                      {alias}: {completed}/{total} validations completed
                    </Text>
                  </Column>
                );
              })}
            </ControlledExpander>
          </>
        )}

        {isAssignedBasedReview(selectedModel) &&
          (assignedReviewTasks[alias] > 0 ||
            assignedValidationTasks[alias]) && (
            <>
              <Text type="h300">Assigned Ground Truth Task Summary</Text>
              {assignedReviewTasks[alias] > 0 && (
                <>
                  <DonutChart
                    totalCount={assignedReviewTasks[alias]}
                    reviewedCount={
                      assignedReviewTasks[alias] - pendingReviewTasks[alias]
                    }
                    isLoading={getListViewLoading === "pending"}
                    title="Total Labeling Task"
                    subtitle="Remaining cases for review"
                  />
                </>
              )}
              {assignedValidationTasks[alias] > 0 && (
                <>
                  <DonutChart
                    totalCount={assignedValidationTasks[alias] || 0}
                    reviewedCount={
                      assignedValidationTasks[alias] -
                        pendingValidationTasks[alias] || 0
                    }
                    isLoading={getListViewLoading === "pending"}
                    title="Total Validation Task"
                    subtitle="Remaining cases for validation"
                  />
                </>
              )}
            </>
          )}
        {isAdminUser(ldap) && <ReviewAssignment />}
        {isAdminUser(ldap) && <GroundTruthReviewAllocationCSVDownloadLink />}
      </Column>
    </Row>
  );
};

export default ModelGroundTruthListView;
