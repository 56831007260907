import {
  GroundTruthData,
  GroundTruthField,
  ReviewResult,
  ReviewStatus,
} from "src/store/modelLifeCycleGroundTruthSlice";
import { LabelHierarchy, Model } from "src/store/modelManagementSlice";
import { compareStringsIgnoreCase } from "src/utils/stringUtil";
import { isMultipleAnswerEqual } from "src/components/audit/helpers";
import {
  INADEQUATE_INFORMATION,
  NO_OBJECT_INVOLVED,
  HIERARCHY_SEPARATOR,
  OTHER,
} from "src/constants";
import { isAdminUser } from "src/utils/permissionUtil";

export const getAnswerLowerCaseAndSeparatedByComma = (answer: string) => {
  if (answer.includes("|")) {
    return answer.split("|").join(", ");
  }
  return answer.toLocaleLowerCase();
};

export const isFieldMultiSelected = (
  groundTruthField: GroundTruthField,
  model: Model,
) => {
  return (
    Object.values(model.fields).find(
      (field) => field.name === groundTruthField.fieldName,
    )?.many || false
  );
};

export const getConfirmedAnswer = (
  groundTruthField: GroundTruthField,
  value: string,
  many: boolean,
) => {
  if (value === ReviewResult.BOTH_CORRECT) {
    return groundTruthField.llmAnswer;
  } else if (value === ReviewResult.NEITHER) {
    return many ? [] : ReviewResult.NEITHER;
  } else {
    return value;
  }
};

export const isAnswerInadequate = (answer: string) => {
  return (
    !answer ||
    compareStringsIgnoreCase(answer, "none") ||
    compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION)
  );
};

export const getParentValue = (str: string): string =>
  str.includes("---") ? str.split("---")[0] : str;

export const getChildValue = (str: string): string =>
  str.includes("---") ? str.split("---").pop() || "" : str;

export const isChildField = (str: string): boolean => str.includes("---");

export const groupByParent = (answers: string[]): { [key: string]: string[] } =>
  answers.reduce((acc: { [key: string]: string[] }, item) => {
    const parentValue = getParentValue(item);
    if (!acc[parentValue]) acc[parentValue] = [];
    acc[parentValue].push(getChildValue(item));
    return acc;
  }, {});

export const isAnswerGroupValid = (answers: string[]): boolean => {
  const hasInadequateInfo = answers.some((item) =>
    compareStringsIgnoreCase(item, INADEQUATE_INFORMATION),
  );

  const hasNoObjectInvolved = answers.some((item) =>
    compareStringsIgnoreCase(item, NO_OBJECT_INVOLVED),
  );

  if (hasInadequateInfo || hasNoObjectInvolved) {
    return answers.length === 1;
  }

  return answers.every(
    (item) => item && !item.toLowerCase().includes("neither"),
  );
};

export const isFieldConfirmedAnswerValid = (
  answer: string | string[],
): boolean => {
  if (!Array.isArray(answer)) {
    const valueToCheck = getChildValue(answer);
    return (
      valueToCheck !== "" && !valueToCheck.toLowerCase().includes("neither")
    );
  }

  if (answer.length === 0) return false;

  if (!isChildField(answer[0])) {
    return isAnswerGroupValid(answer);
  }

  const groupedAnswers = groupByParent(answer);
  return Object.values(groupedAnswers).every(isAnswerGroupValid);
};

const isSpecialAnswer = (answer: string): boolean => {
  return (
    compareStringsIgnoreCase(answer, INADEQUATE_INFORMATION) ||
    compareStringsIgnoreCase(answer, NO_OBJECT_INVOLVED)
  );
};

const validateParentChildRelationship = (
  childValue: string,
  parentAnswer: string,
): boolean => {
  // For special parent answers, child value must be exactly the same
  if (isSpecialAnswer(parentAnswer)) {
    return compareStringsIgnoreCase(childValue, parentAnswer);
  }

  // For normal answers, we're already working with the child value portion
  // No need to check format since groupByParent already handled that
  return true;
};

const validateChildAnswers = (
  childAnswers: string[],
  parentAnswers: string[],
  fieldName: string,
): boolean => {
  // If no answers, it's invalid
  if (childAnswers.length === 0) {
    return false;
  }

  // If parent is a special answer, child must be exactly the same
  if (parentAnswers.length === 1 && isSpecialAnswer(parentAnswers[0])) {
    return (
      childAnswers.length === 1 &&
      compareStringsIgnoreCase(childAnswers[0], parentAnswers[0])
    );
  }

  // Group child answers by parent
  const groupedByParent = groupByParent(childAnswers);

  // For each parent answer, validate its child answers
  for (const parentAnswer of parentAnswers) {
    const childGroup = groupedByParent[parentAnswer];

    // Each parent must have at least one child answer
    if (!childGroup || childGroup.length === 0) {
      return false;
    }

    // If any child is special answer, it should be the only child for that parent
    const hasSpecialChild = childGroup.some(
      (child) => isSpecialAnswer(child), // child is already the child value portion
    );
    if (hasSpecialChild && childGroup.length > 1) {
      return false;
    }

    // Validate each child value
    const allChildrenValid = childGroup.every((childValue) =>
      validateParentChildRelationship(childValue, parentAnswer),
    );

    if (!allChildrenValid) {
      return false;
    }
  }

  return true;
};

const validateField = (
  field: GroundTruthField,
  groundTruth: GroundTruthData,
  model: Model,
): boolean => {
  const hierarchyRule = model.hierarchy?.find(
    (h) => h.childFieldName === field.fieldName,
  );

  // If not a child field, use regular validation
  if (!hierarchyRule) {
    return isFieldConfirmedAnswerValid(field.confirmedAnswer);
  }

  // Find parent field
  const parentField = groundTruth.fields.find(
    (f) => f.fieldName === hierarchyRule.parentFieldName,
  );

  // If no parent field or no parent answer, invalid
  if (!parentField?.confirmedAnswer) {
    return false;
  }

  const parentAnswers = Array.isArray(parentField.confirmedAnswer)
    ? parentField.confirmedAnswer
    : [parentField.confirmedAnswer];

  const childAnswers = Array.isArray(field.confirmedAnswer)
    ? field.confirmedAnswer
    : [field.confirmedAnswer];

  return validateChildAnswers(childAnswers, parentAnswers, field.fieldName);
};

export const isGroundTruthConfirmedAnswerValid = (
  groundTruth: GroundTruthData,
  model: Model,
): boolean => {
  return groundTruth.fields.every((field) =>
    validateField(field, groundTruth, model),
  );
};

export const isAnswerMatch = (field: GroundTruthField, many: boolean) => {
  if (
    isAnswerInadequate(field.userAnswer) ||
    isAnswerInadequate(field.llmAnswer)
  ) {
    return false;
  }
  return (
    compareStringsIgnoreCase(field.userAnswer, field.llmAnswer) ||
    (many && isMultipleAnswerEqual(field.userAnswer, field.llmAnswer))
  );
};

export const getLabel = (
  groundTruthField: GroundTruthField,
  type: ReviewResult,
): string => {
  switch (type) {
    case ReviewResult.BOTH_CORRECT:
      return ReviewResult.BOTH_CORRECT;
    case ReviewResult.LLM_CORRECT:
      return getAnswerLowerCaseAndSeparatedByComma(groundTruthField.llmAnswer);
    case ReviewResult.INADEQUATE:
      return INADEQUATE_INFORMATION;
    case ReviewResult.HUMAN_CORRECT:
      return `${getAnswerLowerCaseAndSeparatedByComma(groundTruthField.userAnswer)}`;
    default:
      return "Others (Expand full list)";
  }
};

export const getChildOptions = (
  hierarchy: LabelHierarchy[] | undefined,
  parentFieldName: string,
  childFieldName: string,
  parentValue: string | string[],
  hasNoObjectInvolved: boolean,
  hasInadequateInfo: boolean,
  hasOther: boolean,
): string[] => {
  if (!hierarchy) return [];
  const hierarchyRule = hierarchy.find(
    (h) =>
      h.parentFieldName === parentFieldName &&
      h.childFieldName === childFieldName,
  );

  if (!hierarchyRule) return [];

  let baseOptions: string[] = [];

  if (Array.isArray(parentValue)) {
    baseOptions = parentValue.reduce((acc: string[], value: string) => {
      const options = (hierarchyRule.options[value] || []).map(
        (childValue) => `${value}${HIERARCHY_SEPARATOR}${childValue}`,
      );
      return [...acc, ...options];
    }, []);
  } else {
    baseOptions = (hierarchyRule.options[parentValue] || []).map(
      (childValue) => `${parentValue}${HIERARCHY_SEPARATOR}${childValue}`,
    );
  }

  // Create a new array with all options, adding parent info for special cases
  return [
    ...baseOptions,
    ...(hasNoObjectInvolved
      ? [`${parentValue}${HIERARCHY_SEPARATOR}${NO_OBJECT_INVOLVED}`]
      : []),
    ...(hasInadequateInfo
      ? [`${parentValue}${HIERARCHY_SEPARATOR}${INADEQUATE_INFORMATION}`]
      : []),
    ...(hasOther ? [`${parentValue}${HIERARCHY_SEPARATOR}${OTHER}`] : []),
  ];
};

export const getGroundTruthDataReviewStatus = (
  groundTruthData: GroundTruthData,
  alias: string,
) => {
  return groundTruthData.reviewContributors?.includes(alias) ||
    groundTruthData.status == ReviewStatus.REVIEWED
    ? ReviewStatus.REVIEWED
    : ReviewStatus.UNREVIEWED;
};

export const getOptionLabel = (option: string): string => {
  if (!option?.trim()) return "";

  if (option.includes(HIERARCHY_SEPARATOR)) {
    const parts = option.split(HIERARCHY_SEPARATOR).filter(Boolean);
    return parts.length ? parts[parts.length - 1] : "";
  }

  return option;
};

/**
 * Checks if ALL fields in the GroundTruthData have no llmClarification
 * @param groundTruthData The GroundTruthData to check
 * @returns true if none of the fields have llmClarification, false if any field has llmClarification
 */
export const hasNoLLMClarification = (
  groundTruthData: GroundTruthData,
): boolean => {
  if (!groundTruthData.fields || groundTruthData.fields.length === 0) {
    return true;
  }

  // Return true only if every field has no llmClarification
  return groundTruthData.fields.every(
    (field) => !field.llmClarification || field.llmClarification.trim() === "",
  );
};

export const isAssignedBasedReview = (model: Model) => {
  return model.requiredReviewerCount;
};

export const isCurrentUserAdminReview = (
  groundTruthData: GroundTruthData,
  model: Model,
  alias: string,
) => {
  return (
    isAssignedBasedReview(model) &&
    !groundTruthData.adminReviewers?.includes(alias)
  );
};

export const hasReviewHistory = (groundTruthData: GroundTruthData): boolean =>
  !!groundTruthData.reviewHistory?.length;

export const showReviewDistribution = (
  groundTruthData: GroundTruthData,
  ldap: string[],
  alias: string,
): boolean =>
  hasReviewHistory(groundTruthData) &&
  (isAdminUser(ldap) ||
    groundTruthData.adminReviewers?.includes(alias) ||
    false);
