import React from "react";
import Link from "@amzn/meridian/link";
import Icon from "@amzn/meridian/icon";
import downloadTokens from "@amzn/meridian-tokens/base/icon/download-small";
import { downloadCSV } from "src/utils/csvUtil";

interface SampleCSVTemplateLinkProps {
  columnNames: string[];
}

const SampleCSVTemplateLink: React.FC<SampleCSVTemplateLinkProps> = ({
  columnNames,
}) => {
  const handleOnClick = () => {
    const csvContent = columnNames.join(",");
    downloadCSV(csvContent, "template.csv");
  };

  return (
    <Link onClick={handleOnClick}>
      <Icon tokens={downloadTokens} />
      Download sample format template
    </Link>
  );
};

export default SampleCSVTemplateLink;
