import { Field } from "src/store/modelManagementSlice";
import React, { useRef, useState } from "react";
import Button from "@amzn/meridian/button";
import Popover from "@amzn/meridian/popover";
import {
  GroundTruthField,
  setHighlightField,
} from "src/store/modelLifeCycleGroundTruthSlice";
import Text from "@amzn/meridian/text";
import { useAppDispatch, useAppSelector } from "src/store/store";
import {
  highlightText,
  removeHighlightedText,
} from "src/components/audit/helpers";
import { getClarification } from "src/store/dataExtractionSlice";

interface AnswerCellsProps {
  groundTruthField: GroundTruthField;
}

const FieldNameCell: React.FC<AnswerCellsProps> = ({ groundTruthField }) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const onHoverButton = () => setOpen(true);
  const onClose = () => setOpen(false);
  const dispatch = useAppDispatch();
  const [doesClarificationExist, setDoesClarificationExist] = useState(true);

  const { highlightField, textHighlightLoading, showHighlight } =
    useAppSelector((state) => state.modelLifeCycleGroundTruthSlice);
  const handleHighlightToggleChange = (
    groundTruthField: GroundTruthField,
    highlightField: string,
  ) => {
    removeHighlightedText();
    if (groundTruthField.fieldName === highlightField) {
      dispatch(setHighlightField(""));
      setDoesClarificationExist(false);
    } else {
      dispatch(setHighlightField(groundTruthField.fieldName));
      highlightText(getClarification(groundTruthField.llmClarification));
      setDoesClarificationExist(true);
    }
  };

  return (
    <>
      {showHighlight && groundTruthField.llmClarification ? (
        <Button
          size={"small"}
          onClick={() =>
            handleHighlightToggleChange(groundTruthField, highlightField)
          }
          type={
            highlightField === groundTruthField.fieldName
              ? "primary"
              : "tertiary"
          }
          ref={buttonRef}
          onMouseLeave={onClose}
          onMouseEnter={onHoverButton}
        >
          {groundTruthField.fieldName}
        </Button>
      ) : (
        <Button size={"small"} disabled={true} type={"secondary"}>
          <Text type={"b200"}>{groundTruthField.fieldName}</Text>
        </Button>
      )}
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
      >
        Click to show LLM highlight for this field
      </Popover>
    </>
  );
};

export default FieldNameCell;
